import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "../components/Container"
import {SectionTitle} from "../components/SectionTitle/styles"
import DevelopomentIcon from "../images/developmentSettings.svg"
import DigitalMarketingIcon from "../images/marketingTriode.svg"
import BrandingIcon from "../images/brandingStar.svg"
import CirclesFour from "../images/circlesfour.svg"
import Startup360 from "../images/startup360.svg"
import EcommerceIcon from "../images/ecommerce.svg"
import GoSocialIcon from "../images/gosocial.svg"
import {ButtonGf, ButtonLink, PopupText} from "../components/Button/styles";
import { colors } from "../const/colors"
import {IoCheckmark} from "react-icons/io5"
import {InfoBox} from "../components/Footer/styles";
import { BannerSection, BenefitsSection} from "../components/WebsitePage/styles";
import CircleTwo from "../images/greyCirclesLeft.svg";
import CircleThree from "../images/grayCirclesRight.svg";
import { BannerStyles} from "../const/inline_styles";
import { OurServicesSection } from "../components/PackagesPage/styles";
import {ServiceBox, ServiceBoxWrapper} from "../components/PackagesPage/styles";
import {CallToAction} from "../components/HomePage/styles";
import {isEmpty, getSectionsData } from "../utils/helpers"

import localData from "../localization/packages.json"

// const flags = {
//     "en": localData.locales[0].value,
//     "es": localData.locales[1].value
// }

const flags = [
    {
        name: "en",
        uri: localData.locales[0].value
    },
    {
        name: "es",
        uri : localData.locales[1].value
    }
]

const GfPackages = ({data, location, pageContext}) => {
    const { lang } = pageContext
    // const loc = data.template.locales.find(l => l.iso === lang)
    const meta = data?.template?.locales.find(l => l.iso === lang)
    const metaStatic = localData.locales.find(l => l.iso === lang)
    const sectionData = getSectionsData(data.template.sections)

    // console.log(sectionData)
    return <Layout location={location} lang={lang} flags={flags}>

        <SEO
            title={ meta?.metaTitle || metaStatic.metaTitle}
            description={ meta?.metaDescription || metaStatic.metaDescription}
            keywords={ meta?.metaKeywords || metaStatic.metaKeywords}
        />
        <BannerSection>
            <Container>
                <h2 style={BannerStyles.h2}>{localData.pageTitle[lang]}</h2>
                <SectionTitle>
                    {localData.sections.hero.title[lang]}
                </SectionTitle>
                <p style={{maxWidth:"900px"}}>{localData.sections.hero.description[lang]}</p>
                <div className="info-box-list">
                    <InfoBox step={1} icon={<img src={DevelopomentIcon} alt="flexible" />} text={localData.sections.hero.group.first[lang]} color={colors.grayColor} />
                    <InfoBox step={2} size="27px" icon={<img src={DigitalMarketingIcon} alt="customized" />} text={localData.sections.hero.group.second[lang]} color={colors.grayColor} />
                    <InfoBox step={3} icon={<img src={BrandingIcon} alt="optimized" />} text={localData.sections.hero.group.third[lang]} color={colors.grayColor} />
                </div>
                <div className="free-consultation">
                    <PopupText text={localData.sections.hero.button.label[lang]} url="https://calendly.com/tina-greenflamingo/30min" />
                </div>
            </Container>
            <div className="bg-image">
                <img src={CirclesFour} alt="circles"/>
            </div>
        </BannerSection>
        <BenefitsSection>
            <Container>
                <SectionTitle align="center">{localData.sections.benefits.title[lang]}</SectionTitle>
                <div className="content">
                    <p style={{textAlign:"center"}}>{localData.sections.benefits.description[lang]}</p>

                    <ul>
                        <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html: localData.sections.benefits.group.first[lang]}} /></li>
                        <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html: localData.sections.benefits.group.second[lang]}} /></li>
                        <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html: localData.sections.benefits.group.third[lang]}} /></li>
                        <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html: localData.sections.benefits.group.fourth[lang]}} /></li>
                        <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html: localData.sections.benefits.group.fifth[lang]}} /></li>
                        <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html: localData.sections.benefits.group.sixth[lang]}} /></li>
                    </ul>
                    <div className="get-in-touch">
                        <h4>{localData.sections.benefits.subTitle[lang]}</h4>
                        <span>{localData.sections.benefits.subDescription[lang]}</span>
                        {/*<ButtonGf>Start</ButtonGf>*/}
                        <div className="free-consultation free-consultation--flex">
                            <PopupText url="https://calendly.com/tina-greenflamingo/30min" text={localData.sections.benefits.button.label[lang]} />
                        </div>
                    </div>
                </div>
            </Container>
            <img className="left" src={CircleTwo} alt="circles two"/>
            <img className="right" src={CircleThree} alt="circles three"/>
        </BenefitsSection>
        <OurServicesSection>
            <Container>
                <SectionTitle align="center">{sectionData.packages.title[lang]}</SectionTitle>
                <ServiceBoxWrapper>
                    {sectionData.packages.selectedModules && sectionData.packages.selectedModules.map((sm, index) => {
                        const loc = sm.locales.find(l => l.iso === lang)
                        return <ServiceBox key={index} iconWidth="53px" icon={sm.imageFile?.childImageSharp.fluid} title={loc.title}
                                           subtitle="Introduction of a brand">
                            <div dangerouslySetInnerHTML={{__html: loc.content}} />
                        </ServiceBox>
                    }) }

{/*                    <ServiceBox iconWidth="53px" icon={Startup360} title="Start-Up 360" subtitle="Introduction of a brand">*/}
{/*                        <h5>Branding</h5>*/}
{/*                        <span>Brand guidelines</span>*/}
{/*                        <span>Print material</span>*/}
{/*                        <span>Digital content</span>*/}
{/*                        <h5>Digital Set up</h5>*/}
{/*                        <span>Domain</span>*/}
{/*                        <span>Web Hosting</span>*/}
{/*                        <span>Email Hosting</span>*/}
{/*                        <span>Social media accounts</span>*/}
{/*                        <h5>Development</h5>*/}
{/*                        <span>Web / mobile solution</span>*/}
{/*                        <span>CMS integration (options)</span>*/}
{/*                    </ServiceBox>*/}
{/*                    <ServiceBox icon={EcommerceIcon} title="E-commerce" subtitle="Complete set up*/}
{/*of an online store">*/}
{/*                        <h5>Branding</h5>*/}
{/*                        <span>Brand guidelines</span>*/}
{/*                        <span>Print material</span>*/}
{/*                        <span>Digital content</span>*/}
{/*                        <h5>Digital Set up</h5>*/}
{/*                        <span>Domain</span>*/}
{/*                        <span>Web Hosting</span>*/}
{/*                        <span>Email Hosting</span>*/}
{/*                        <span>Social media accounts</span>*/}
{/*                        <h5>Online Shop</h5>*/}
{/*                        <span>Website</span>*/}
{/*                        <span>E-commerce platform</span>*/}
{/*                    </ServiceBox>*/}
{/*                    <ServiceBox iconBottomSpace="1.95rem" icon={GoSocialIcon} title="Go-Social" subtitle="Interaction with the target audience">*/}
{/*                        <h5>Branding</h5>*/}
{/*                        <span>Brand guidelines</span>*/}
{/*                        <span>Print material</span>*/}
{/*                        <span>Digital content</span>*/}
{/*                        <h5>Content Marketing</h5>*/}
{/*                        <span>Images and illustrations</span>*/}
{/*                        <span>Videos and animations</span>*/}
{/*                        <span>Blogs and newsletters</span>*/}
{/*                        <h5>Social Media Management</h5>*/}
{/*                        <span>Strategy</span>*/}
{/*                        <span>Content calendar</span>*/}
{/*                        <span>Community engagement</span>*/}
{/*                    </ServiceBox>*/}
                </ServiceBoxWrapper>
                <CallToAction>
                    <h2>{localData.sections.banner.title[lang]}</h2>
                    <p>{localData.sections.banner.description[lang]}</p>
                    {/*<ButtonGf background="white" color={colors.greenColor} hoverColor="white" bgHoverColor={colors.grayColor}>Start</ButtonGf>*/}
                    <ButtonLink background="white" bgHoverColor={colors.grayColor} margin="auto" color={colors.lightGrey} width="150px" to={localData.sections.banner.button.uri[lang]}>{localData.sections.banner.button.label[lang]}</ButtonLink>
                </CallToAction>
            </Container>
        </OurServicesSection>
    </Layout>
}

export const query = graphql`
  query($templateName: String! ) {
    template(name: {eq: $templateName }) {
      sections {
        keyPrefix
        ... on SectionModuleGet {
          __typename
          items {
            key
            ... on DataItemTextGet {
              __typename
              locales {
                iso
                value
              }
            }
          }
          selectedModules {
            ... on OfferGet {
              uid
              type
              locales {
              content
              description
              iso
              title
            }
            asset {
                uri
                locales {
                    alt
                }
            }    
            imageFile {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
              publicURL
                }
            }
          }
        }
      }
      locales {
        iso
        metaTitle
        metaKeywords
        metaDescription
      }
    }
  }
`

export default GfPackages
